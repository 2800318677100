import {
  Container,
  HeaderConfig,
  BottomConfig,
  ImageContact,
  IconsEnd,
  PopoverContent,
  PopoverArrow,
  Item,
  FunnelSimpleStyled,
  FilterContainer,
  TitleFilter,
  TagAbbreviation,
  TextAbbreviation,
  XCircleStyled,
  BottomConfigSup,
  SelectedTagsContainer,
  ClearFilters,
  ContainerTags,
  ArchiveIconStyled,
  ArchiveText,
  NameSession,
  SwitchRoot,
  SwitchThumb,
  SwitchContainer,
  LabelSwitch,
  DateContainer,
  InputContainer,
  LabelContainer,
  Button,
  ButtonContainer,
} from './styles'
import Input from '../../../../../../shared/components/Input'
import SettingsIcon from '@mui/icons-material/Settings'
import LogoutIcon from '@mui/icons-material/Logout'
import * as Popover from '@radix-ui/react-popover'
import LoginService from '../../../../../../shared/services/Login/LoginService'
import TagsService from '../../../../../../shared/services/Tags/TagsService'
import { useNavigate } from 'react-router-dom'
import { useSession } from '../../../../../../hook/session'
import { useEffect, useState } from 'react'
import { getTextColor } from '../../../../../../utils/format'
import { useTriggers } from '../../../../../../hook/triggers'

const SearchMenu = ({
  searchTerm,
  setSearchTerm,
  selectedTags,
  setSelectedTags,
  setSelectedContact,
  setArchivedContacts,
  archivedContacts,
  listArchivedContacts,
  typeSearch,
  setTypeSearch,
  dateMin,
  setDateMin,
  dateMax,
  setDateMax,
  triggerDate,
  setTriggerDate,
}) => {
  const navigate = useNavigate()
  const { sessionMedia, getSessionData } = useSession()
  const [tags, setTags] = useState([])
  const { setEnableNewMessage } = useTriggers()
  const handleLogout = () => {
    LoginService.logout()
  }

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value)
    setSelectedContact(null)
  }

  useEffect(() => {
    const getTags = async () => {
      const response = await TagsService.getTags()
      setTags(response.data)
    }
    getTags()
  }, [])

  const renderTags = () => {
    return tags
      ?.filter((tag) => !selectedTags.includes(tag))
      .map((tag) => (
        <TagAbbreviation
          key={tag.id_tag}
          $color={tag.color_hex_tag}
          onClick={() => handleTagClick(tag)}
        >
          <TextAbbreviation $color={() => getTextColor(tag.color_hex_tag)}>
            {tag.abbreviation_tag}
          </TextAbbreviation>
        </TagAbbreviation>
      ))
  }

  const renderSelectedTags = () => {
    return selectedTags.map((tag) => (
      <TagAbbreviation
        key={tag.id_tag}
        $color={tag.color_hex_tag}
        $selected={selectedTags.includes(tag)}
        onClick={() => handleTagClick(tag)}
      >
        <TextAbbreviation
          $color={() => getTextColor(tag.color_hex_tag)}
          $selected={selectedTags.includes(tag)}
        >
          {tag.abbreviation_tag}
        </TextAbbreviation>
        <XCircleStyled $color={() => getTextColor(tag.color_hex_tag)} />
      </TagAbbreviation>
    ))
  }

  useEffect(() => {
    if (selectedTags.length > 0) {
      setEnableNewMessage(false)
    } else {
      setEnableNewMessage(true)
    }
  }, [selectedTags, setEnableNewMessage])

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((item) => item !== tag))
    } else {
      setSelectedTags([...selectedTags, tag])
    }
    setSelectedContact(null)
  }

  const handleIntervalDate = () => {
    setTriggerDate((prev) => !prev)
  }

  const handleClearDate = () => {
    setDateMin('')
    setDateMax('')
    setTriggerDate((prev) => !prev)
  }

  const handleClearFilters = () => {
    setSelectedTags([])
    setDateMin('')
    setDateMax('')
    setTriggerDate((prev) => !prev)
  }

  useEffect(() => {
    setSelectedContact(null)
    setSearchTerm('')
    setSelectedTags([])
  }, [typeSearch, setSearchTerm, setSelectedTags, setSelectedContact])

  return (
    <Container>
      <HeaderConfig>
        <ImageContact src={sessionMedia} />
        <NameSession>
          {`Olá, ${getSessionData().fullName || 'Usuário'}`}
        </NameSession>
        <IconsEnd>
          <SettingsIcon
            fontSize="large"
            style={{ color: 'white', marginLeft: '10px' }}
            cursor="pointer"
            onClick={() => navigate('/settings')}
          />
          <LogoutIcon
            fontSize="large"
            style={{ color: 'white', marginLeft: '10px' }}
            cursor="pointer"
            onClick={handleLogout}
          />
        </IconsEnd>
      </HeaderConfig>
      <BottomConfig>
        <BottomConfigSup>
          <Input
            value={searchTerm}
            placeholder="Pesquisar"
            style={{ marginLeft: '5px', marginTop: '5px' }}
            onChange={handleInputChange}
          />
          <ArchiveIconStyled
            isArchived={archivedContacts ? true : undefined}
            onClick={() => {
              setArchivedContacts((prev) => !prev)
              setSelectedContact(null)
            }}
          />
          <ArchiveText>{listArchivedContacts.length}</ArchiveText>
          <Popover.Root>
            <Popover.Trigger asChild>
              <FunnelSimpleStyled />
            </Popover.Trigger>
            <Popover.Portal>
              <PopoverContent className="PopoverContent" sideOffset={5}>
                <FilterContainer>
                  <TitleFilter>Selecione o tipo de pesquisa:</TitleFilter>
                  <SwitchContainer>
                    <LabelSwitch>Contatos</LabelSwitch>
                    <SwitchRoot
                      onCheckedChange={(checked) => {
                        setTypeSearch(checked ? 'messages' : 'contacts')
                      }}
                      defaultChecked={typeSearch === 'messages'}
                    >
                      <SwitchThumb />
                    </SwitchRoot>
                    <LabelSwitch>Mensagens</LabelSwitch>
                  </SwitchContainer>

                  {typeSearch === 'contacts' && (
                    <>
                      <TitleFilter>Filtrar por tags:</TitleFilter>
                      <ContainerTags>{renderTags()}</ContainerTags>
                      {selectedTags.length > 0 ? (
                        <Item onClick={() => setSelectedTags([])}>
                          <ClearFilters>Limpar tags</ClearFilters>
                        </Item>
                      ) : (
                        <p>Nenhuma tag selecionada!</p>
                      )}
                      <TitleFilter>Filtrar por data:</TitleFilter>
                      <DateContainer>
                        <InputContainer>
                          <LabelContainer>
                            <p>De:</p>
                            <Input
                              type="date"
                              label="Data mínima"
                              value={dateMin}
                              onChange={(event) =>
                                setDateMin(event.target.value)
                              }
                              style={{
                                backgroundColor: 'white',
                                color: 'black',
                              }}
                            />
                          </LabelContainer>
                          <LabelContainer>
                            <p>Até:</p>
                            <Input
                              type="date"
                              label="Data máxima"
                              value={dateMax}
                              style={{
                                backgroundColor: 'white',
                                color: 'black',
                              }}
                              min={dateMin}
                              onChange={(event) =>
                                setDateMax(event.target.value)
                              }
                            />
                          </LabelContainer>
                        </InputContainer>
                        <ButtonContainer>
                          <Button onClick={() => handleIntervalDate()}>
                            Buscar
                          </Button>
                          <Button onClick={() => handleClearDate()}>
                            Limpar
                          </Button>
                          <Button
                            className="delete"
                            onClick={() => handleClearFilters()}
                          >
                            Limpar filtros
                          </Button>
                        </ButtonContainer>
                      </DateContainer>
                    </>
                  )}
                </FilterContainer>
                <PopoverArrow className="PopoverArrow" />
              </PopoverContent>
            </Popover.Portal>
          </Popover.Root>
        </BottomConfigSup>
        <SelectedTagsContainer>{renderSelectedTags()}</SelectedTagsContainer>
      </BottomConfig>
    </Container>
  )
}

export default SearchMenu

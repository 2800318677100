import React, { useEffect, useState } from 'react'
import StickerService from '../../../../../../../../shared/services/Sticker/StickerService'
import FileService from '../../../../../../../../shared/services/File/FileService'
import {
  LibraryContainer,
  Title,
  StickerList,
  StickerCard,
  StickerImage,
  ButtonContainer,
  LoadingMessage,
  ErrorMessage,
  PaperPlaneIcon,
  TrashIcon,
} from './styles'

const StickerLibrary = ({ onContactClick, handleSendMessage }) => {
  const [stickers, setStickers] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchStickers = async () => {
      setLoading(true)
      setError(null)

      try {
        const response = await StickerService.FindAllStickers()
        const stickerData = response.data

        const stickerPromises = stickerData.map(async (sticker) => {
          try {
            const fileResponse = await FileService.getSrc(
              sticker.file_name_sticker,
              sticker.file_type_sticker,
            )
            return {
              ...sticker,
              base64: fileResponse.data.base64,
            }
          } catch (fileError) {
            console.error('Erro ao buscar o arquivo:', fileError)
            return null
          }
        })

        const resolvedStickers = await Promise.all(stickerPromises)
        const validStickers = resolvedStickers.filter(
          (sticker) => sticker !== null,
        )

        validStickers.sort((a, b) => {
          const dateA = a.used_at_sticker || ''
          const dateB = b.used_at_sticker || ''
          if (dateA === dateB) {
            return 0
          }
          if (!dateA) return 1
          if (!dateB) return -1
          return dateB.localeCompare(dateA)
        })
        // console.log(validStickers)
        setStickers(validStickers)
      } catch (apiError) {
        console.error('Erro ao buscar stickers:', apiError)
        setError(apiError)
      } finally {
        setLoading(false)
      }
    }

    fetchStickers()
  }, [])

  const handleSendSticker = (base64) => {
    console.log('entrou no sendSticker')
    handleSendMessage(base64, 'sticker')
  }
  const handleDeleteClick = (filename) => {
    StickerService.DeleteSticker(filename)
      .then((resp) => {
        const updatedStickers = stickers.filter(
          (value) => value.file_name_sticker !== filename,
        )
        setStickers(updatedStickers)
      })
      .catch((error) => {
        console.error('Erro ao deletar o sticker:', error)
      })
  }

  if (loading) {
    return (
      <LibraryContainer>
        <LoadingMessage>Carregando...</LoadingMessage>
      </LibraryContainer>
    )
  }

  if (error) {
    return (
      <LibraryContainer>
        <ErrorMessage>
          Erro ao carregar os stickers: {error.message}
        </ErrorMessage>
      </LibraryContainer>
    )
  }

  return (
    <LibraryContainer>
      <Title>Biblioteca de Stickers</Title>
      <StickerList>
        {stickers.map((sticker, index) => (
          <StickerCard key={index}>
            <StickerImage
              key={sticker.file_name_sticker}
              src={sticker.base64}
              alt={`Sticker ${sticker.id || sticker.file_name_stiker}`}
            />
            <ButtonContainer>
              <TrashIcon
                onClick={() => handleDeleteClick(sticker.file_name_sticker)}
              />
              <PaperPlaneIcon
                onClick={() => handleSendSticker(sticker.base64)}
              />
            </ButtonContainer>
          </StickerCard>
        ))}
      </StickerList>
    </LibraryContainer>
  )
}

export default StickerLibrary

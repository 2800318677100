import { useState, useEffect } from 'react'
import Dialog from '../../../../../../../../shared/components/Dialog'

const DialogMidia = ({ open, onChangeOpen, socket, dataContact }) => {
  const [midias, setMidias] = useState([])

  useEffect(() => {
    socket.emit(
      'getMessages',
      { idContact: dataContact.id },
      {
        limit: 10,
        offset: 0,
        only_midia: true,
      },
      (err, resp) => {
        if (!err) {
          // console.log('response: ', resp)
          setMidias(resp) // Atualiza o estado com os dados recebidos
        } else {
          console.log(err)
        }
      },
    )
  }, [socket, dataContact.id])

  return (
    <Dialog open={open} onChangeOpen={onChangeOpen}>
      {midias.length > 0 ? (
        midias.map((midia, index) => (
          <h1 key={index}>{midia.file_name_midia}</h1>
        ))
      ) : (
        <h1>Nenhuma mídia encontrada</h1>
      )}
    </Dialog>
  )
}

export default DialogMidia

import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  ContainerCollumContent,
  MessagesBox,
  SendMessages,
  AttachFileIconStyled,
  SendStyled,
  NoteStyled,
  NoMessage,
  NoMessageP,
  MessagesContainer,
  MicrophoneStyled,
  AudioPlayerStyled,
  StyledTrash,
  EmojiStyled,
  GalleryFile,
  ContainerNotSelectedContact,
  Title,
  ContainerPreview,
  ContainerRec,
  RecTime,
  ContainerActions,
  LoadingContainer,
  NoMessageH1,
} from './styles'
import * as Popover from '@radix-ui/react-popover'
import ContactSuperior from './components/ContactSuperior'
import Message from './components/Message'
import Modal from './components/Modal'
import { getFileType, formatTime, getMessages } from '../../../../utils/format'
import { jwtDecode } from 'jwt-decode'
import EmojiModal from './components/Emoji'
import AudioLibrary from './components/AudioLibrary'
import LoadingSpinner from '../../../../shared/components/LoadingSpinner'
import LocalStorage from '../../../../utils/LocalStorage'
import InputChat from '../../../../shared/components/InputChat'
import { useTriggers } from '../../../../hook/triggers'
import { useTheme } from 'styled-components'

const Chat = ({
  socket,
  selectedContact,
  lateralContacts,
  setLateralContacts,
  currentMessages,
  messagesEndRef,
  navigation,
  setNavigation,
  LIMIT_MESSAGES,
  setMessages,
  handleArchiveContact,
}) => {
  const [newMessage, setNewMessage] = useState('')
  const [isLoadingMessages, setIsLoadingMessages] = useState(true)
  const [isNote, setIsNote] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEmojiOpen, setIsEmojiOpen] = useState(false)
  const [file, setFile] = useState(null)
  const [title, setTitle] = useState('')
  const [isRecording, setIsRecording] = useState(false)
  const [modalAudioLibary, setModalAudioLibary] = useState(false)
  const [mediaRecorder, setMediaRecorder] = useState(null)
  const [audioBlob, setAudioBlob] = useState(null)
  const [senderUserID, setSenderUserID] = useState(null)
  const [recordingTime, setRecordingTime] = useState(0)
  const recordingInterval = useRef(null)
  const scrollPosition = useRef(null)
  const theme = useTheme()
  const getDraft = (contactId) => {
    return LocalStorage.getValue(`draft-${contactId}`)
  }
  const draft = getDraft(selectedContact)
  const { searchedMsg, typeSearch } = useTriggers()

  const scrollMessages = useCallback(() => {
    socket.emit(
      'getMessages',
      { idContact: selectedContact },
      { limit: searchedMsg.pageNumber * 25, offset: 0 },
      (err, response) => {
        if (!err) {
          const msg = getMessages(response)
          setMessages((prev) => ({
            ...prev,
            [selectedContact]: msg,
          }))
        }
      },
    )

    setTimeout(() => {
      const message = document.getElementById(searchedMsg.lastMessageId)
      if (message) {
        message.scrollIntoView({ behavior: 'smooth', block: 'center' })

        const targetMain = message.querySelector('main')
        const targetSvg = message.querySelectorAll('svg')

        if (targetMain && targetSvg) {
          targetMain.style.transition = 'background-color 3s ease'
          targetSvg.forEach((svg) => {
            svg.style.transition = 'fill 3s ease'
          })
          targetMain.style.borderRadius = '10px'
          targetMain.style.backgroundColor = theme.colors.guto.Vermelho
          targetSvg.forEach((svg) => {
            svg.style.fill = theme.colors.guto.Vermelho
          })
          setTimeout(() => {
            targetMain.style.backgroundColor = ''
            targetSvg.forEach((svg) => {
              svg.style.fill = ''
            })
          }, 3000)
        }
      }
    }, 250)
  }, [
    searchedMsg,
    selectedContact,
    setMessages,
    socket,
    theme.colors.guto.Vermelho,
  ])

  useEffect(() => {
    if (searchedMsg) {
      scrollMessages()
    }
  }, [searchedMsg, scrollMessages])

  useEffect(() => {
    if (typeSearch === 'contacts') {
      setTimeout(() => {
        const messagesBox = document.getElementById('messagesBox')
        if (messagesBox) {
          messagesBox.scrollTop = messagesBox.scrollHeight
        }
      }, 500)
    }
  }, [selectedContact, typeSearch])

  const manipulateMessages = useCallback((messages) => {
    if (messages.length === 0) return []

    let currentDay = null
    const messagesWithFlags = []

    messages.forEach((message) => {
      const date = new Date(message.sendDateMessage)
      const day = date.getDate()

      if (day !== currentDay) {
        currentDay = day
        messagesWithFlags.push({
          flag: `Mensagens do dia ${date.toLocaleDateString()}`,
          isFlag: true,
          date,
        })
      }

      messagesWithFlags.push(message)
    })
    return messagesWithFlags
  }, [])

  useEffect(() => {
    if (draft) {
      setNewMessage(draft)
    } else {
      setNewMessage('')
    }
    if (currentMessages) {
      setIsLoadingMessages(false)
    }
  }, [currentMessages, selectedContact, draft])

  useEffect(() => {
    if (audioBlob) {
      const audio = new File([audioBlob], 'audioMessage.webm', {
        type: 'audio/webm',
      })
      const reader = new FileReader()

      reader.onloadend = () => {
        const base64Audio = reader.result
        setFile(base64Audio)
        setTitle('Mensagem de Áudio')
      }

      reader.readAsDataURL(audio)
    }
  }, [audioBlob])

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      const decoded = jwtDecode(token)
      setSenderUserID(() => decoded.id)
    }
  }, [])

  const startRecording = () => {
    setFile(null)
    setTitle('')
    setRecordingTime(0)
    try {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        const mediaRecorder = new MediaRecorder(stream)
        setMediaRecorder(mediaRecorder)
        mediaRecorder.start()
        setIsRecording(true)

        recordingInterval.current = setInterval(() => {
          setRecordingTime((prevTime) => prevTime + 1)
        }, 1000)

        mediaRecorder.ondataavailable = (e) => {
          setAudioBlob(e.data)
        }
      })
    } catch (err) {
      console.log('Error recording audio', err)
    }
  }

  const stopRecording = () => {
    mediaRecorder.stop()
    setIsRecording(false)
    clearInterval(recordingInterval.current)
  }

  const handleSendMessage = (base64 = null, type = null) => {
    try {
      console.log('chegou no try')
      if ((newMessage.trim() || file || base64) && selectedContact) {
        const data = {
          typeMessage: type || (file ? getFileType(file) : 'chat'),
          toContactMessage: selectedContact,
          contentMessage: file ? title : newMessage,
          isNoteMessage: isNote,
          idReplyMessage: null,
          fileContentMessage: file || base64,
          senderUserId: senderUserID,
        }
        console.log(data)
        socket.emit('sendMessage', data, (err) => {
          if (!err) {
            const lastMessageText = file ? title : newMessage
            setLateralContacts((prev) =>
              prev.map((contact) => {
                if (contact.id === selectedContact) {
                  return {
                    ...contact,
                    lastMessage: lastMessageText,
                    isViewdMessage: true,
                  }
                }
                return contact
              }),
            )
          } else {
            console.log(err)
          }
        })
        setNewMessage('')
        setIsNote(false)
        setFile(null)
        setTitle('')
        LocalStorage.removeValue(`draft-${selectedContact}`)
      }
    } catch (error) {
      console.log(error)
    }
    onCloseModal()
  }

  const onCloseModal = () => {
    setIsModalOpen(false)
    setFile(null)
    setTitle('')
  }

  const handleFileDrop = (e) => {
    e.preventDefault()

    const fileDropped = e.dataTransfer.files[0]
    if (fileDropped) {
      const reader = new FileReader()
      reader.onload = () => {
        setFile(reader.result)
      }
      reader.readAsDataURL(fileDropped)
    }
  }

  const handleFileSelect = (file) => {
    const reader = new FileReader()
    reader.onload = () => {
      setFile(reader.result)
    }
    reader.readAsDataURL(file)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
  }

  const onEmojiModalClose = () => {
    setIsEmojiOpen(false)
  }

  const handleDraft = (contactId, message) => {
    LocalStorage.setValue(`draft-${contactId}`, message)
  }

  const handleScrollMessages = () => {
    const messagesBox = document.getElementById('messagesBox')

    if (
      messagesBox.scrollTop === 0 &&
      currentMessages.length >= LIMIT_MESSAGES
    ) {
      scrollPosition.current = messagesBox.scrollHeight - messagesBox.scrollTop

      socket.emit(
        'getMessages',
        { idContact: selectedContact },
        { limit: LIMIT_MESSAGES, offset: currentMessages.length },
        (err, response) => {
          if (!err) {
            const msg = getMessages(response)

            setMessages((prev) => ({
              ...prev,
              [selectedContact]: [...msg, ...prev[selectedContact]],
            }))

            setTimeout(() => {
              messagesBox.scrollTop =
                messagesBox.scrollHeight - scrollPosition.current
            }, 0)
          }
        },
      )
    }
  }

  if (!selectedContact) {
    return (
      <ContainerNotSelectedContact $navigation={navigation}>
        <Title>Bem-vindo ao GutoBot</Title>
      </ContainerNotSelectedContact>
    )
  }

  return (
    <ContainerCollumContent $navigation={navigation}>
      {selectedContact && (
        <ContactSuperior
          setNavigation={setNavigation}
          socket={socket}
          lateralContacts={lateralContacts}
          setLateralContacts={setLateralContacts}
          dataContact={
            lateralContacts.find((contact) => contact.id === selectedContact) ||
            {}
          }
          handleArchiveContact={handleArchiveContact}
        />
      )}
      {isLoadingMessages ? (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      ) : (
        <MessagesBox>
          {currentMessages.length >= 1 ? (
            <MessagesContainer
              id="messagesBox"
              onScroll={() => handleScrollMessages()}
            >
              {manipulateMessages(currentMessages).map((message, index) => (
                <Message
                  key={index}
                  message={message}
                  setMessages={setMessages}
                  socket={socket}
                  currentMessages={currentMessages}
                  dataContact={
                    lateralContacts.find(
                      (contact) => contact.id === selectedContact,
                    ) || {}
                  }
                  setLateralContacts={setLateralContacts}
                />
              ))}
              <div ref={messagesEndRef} />
            </MessagesContainer>
          ) : currentMessages.length === 0 ? (
            <NoMessage>
              <NoMessageH1>Nenhuma mensagem</NoMessageH1>
              <NoMessageP>
                Mande uma mensagem para iniciar a conversa
              </NoMessageP>
            </NoMessage>
          ) : null}
        </MessagesBox>
      )}
      <SendMessages>
        {getFileType(file) === 'ptt' || getFileType(file) === 'audio' ? (
          <ContainerPreview>
            <AudioPlayerStyled controls src={file} />
            <ContainerActions>
              <StyledTrash
                onClick={() => {
                  setFile(null)
                  setTitle('')
                }}
              />
              <SendStyled onClick={() => handleSendMessage()} />
            </ContainerActions>
          </ContainerPreview>
        ) : (
          <>
            <ContainerActions>
              <Popover.Root>
                <Popover.Trigger asChild>
                  <EmojiStyled
                    onClick={() => {
                      setIsEmojiOpen(true)
                    }}
                  />
                </Popover.Trigger>
                <Popover.Content>
                  <EmojiModal
                    isOpen={isEmojiOpen}
                    onClose={onEmojiModalClose}
                    setNewMessage={setNewMessage}
                    handleSendMessage={handleSendMessage}
                  />
                </Popover.Content>
              </Popover.Root>
              <Popover.Root>
                <Popover.Trigger asChild>
                  <GalleryFile
                    onClick={() => {
                      setModalAudioLibary(true)
                    }}
                  />
                </Popover.Trigger>
                <Popover.Content>
                  <AudioLibrary
                    isOpen={modalAudioLibary}
                    socket={socket}
                    sendMessage={handleSendMessage}
                    setFile={setFile}
                  />
                </Popover.Content>
              </Popover.Root>
            </ContainerActions>
            <InputChat
              type="text"
              placeholder="Digite sua mensagem"
              value={newMessage}
              onChange={(e) => {
                setNewMessage(e.target.value)
                handleDraft(selectedContact, e.target.value)
              }}
              handleSendMessage={handleSendMessage}
              conversationId={selectedContact}
              draft={draft}
            />
            <ContainerActions>
              <AttachFileIconStyled onClick={() => setIsModalOpen(true)} />
              <NoteStyled
                onClick={() => {
                  setIsNote(!isNote)
                }}
                style={{ cursor: 'pointer' }}
                $isNote={isNote}
              />
              <ContainerRec>
                <MicrophoneStyled
                  onClick={() => {
                    if (isRecording) {
                      stopRecording()
                    } else {
                      startRecording()
                    }
                  }}
                  $isRecording={isRecording}
                />
                <RecTime $isRecording={isRecording}>
                  {formatTime(recordingTime)}
                </RecTime>
              </ContainerRec>
              <SendStyled
                onClick={() => handleSendMessage()}
                style={{ cursor: 'pointer' }}
              />
            </ContainerActions>
          </>
        )}
      </SendMessages>

      <Modal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        onDrop={handleFileDrop}
        file={file}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        handleFileSelect={handleFileSelect}
        title={title}
        setTitle={setTitle}
        handleSendMessage={handleSendMessage}
      />
    </ContainerCollumContent>
  )
}

export default Chat

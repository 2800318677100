// import React from 'react'
// import {
//   ModalImageSection,
//   ModalNameSection,
//   ModalNumberSection,
//   ModalDescriptionSection,
//   InputBox,
//   InputLabel,
//   ButtonGroup,
//   NameContactEnter,
//   ReadMoreButton,
//   ModalImage,
//   EditButton,
//   SyncButton,
//   ArchiveButton,
//   NameContact,
//   DivColumn,
//   ErrorMessage,
// } from './styles'
// import Dialog from '../../../../../../../../shared/components/Dialog'
// import imageContato from '../../../../../../../../assets/images/imageContato'
// import {
//   formatarDataParaBrasil,
//   extractImage,
// } from '../../../../../../../../utils/format'

// const DialogEdit = ({
//   isOpen,
//   onChangeOpen,
//   contactImage,
//   dataContact,
//   contactDateEnter,
//   loading,
//   verifyIsRoot,
//   handleSyncImage,
//   isOpenEdit,
//   nameContact,
//   setNameContact,
//   error,
//   handleSave,
//   editContact,
//   editedNames,
//   handleArchiveContact,
//   expandedDescription,
//   isLongDescription,
//   toggleDescription,
// }) => {
//   const MAX_DESCRIPTION_LENGTH = 150
//   const renderEnterDate = () => {
//     if (verifyIsRoot()) {
//       return (
//         <div>
//           <span>{loading ? 'Carregando...' : 'Entrou em: '}</span>
//         </div>
//       )
//     } else {
//       return (
//         <div>
//           <span>{loading ? 'Carregando...' : 'Atribuído em: '}</span>
//         </div>
//       )
//     }
//   }

//   return (
//     <Dialog open={isOpen} onChangeOpen={onChangeOpen}>
//       <ModalImageSection>
//         {extractImage(contactImage[dataContact.id]) !== 'null' ? (
//           <ModalImage
//             src={contactImage[dataContact.id]}
//             alt="Imagem do contato"
//           />
//         ) : (
//           <ModalImage src={imageContato} alt="Imagem do contato" />
//         )}
//       </ModalImageSection>
//       <ModalNameSection>
//         <NameContactEnter>
//           {renderEnterDate()}
//           <span>
//             {formatarDataParaBrasil(contactDateEnter.contactEnterDate)}
//           </span>
//         </NameContactEnter>
//       </ModalNameSection>
//       <ModalNameSection>
//         {isOpenEdit ? (
//           <>
//             <ButtonGroup>
//               <SyncButton onClick={handleSyncImage}>
//                 Sincronizar Imagem
//               </SyncButton>
//               {dataContact.isArchivedContact ? (
//                 <ArchiveButton
//                   onClick={() => {
//                     handleArchiveContact(dataContact.id, dataContact.name)
//                   }}
//                 >
//                   Desarquivar contato
//                 </ArchiveButton>
//               ) : (
//                 <ArchiveButton
//                   onClick={() => {
//                     handleArchiveContact(
//                       dataContact.id,
//                       dataContact.name,
//                       !dataContact.isArchivedContact,
//                     )
//                   }}
//                 >
//                   Arquivar contato
//                 </ArchiveButton>
//               )}
//             </ButtonGroup>
//             <InputLabel>Nome</InputLabel>
//             <InputBox
//               maxLength={20}
//               value={nameContact}
//               onChange={(e) => setNameContact(e.target.value)}
//             />
//             {error && <ErrorMessage>{error}</ErrorMessage>}
//           </>
//         ) : (
//           <DivColumn>
//             <NameContact>
//               {editedNames[dataContact.id] ||
//                 dataContact.name ||
//                 'Nome Indisponível'}
//             </NameContact>
//           </DivColumn>
//         )}
//       </ModalNameSection>
//       <ModalDescriptionSection>
//         <InputLabel>Descrição</InputLabel>
//         <p>
//           {isLongDescription
//             ? expandedDescription
//               ? dataContact.summaryContact
//               : `${dataContact.summaryContact.substring(0, MAX_DESCRIPTION_LENGTH)}...`
//             : dataContact.summaryContact}
//         </p>
//         {isLongDescription && (
//           <ReadMoreButton onClick={toggleDescription}>
//             {expandedDescription ? 'Ler menos' : 'Ler mais'}
//           </ReadMoreButton>
//         )}
//       </ModalDescriptionSection>
//       <ModalNumberSection>{dataContact.number}</ModalNumberSection>
//       <EditButton onClick={isOpenEdit ? handleSave : editContact}>
//         {isOpenEdit ? 'Salvar' : 'Editar'}
//       </EditButton>
//     </Dialog>
//   )
// }

// export default DialogEdit

import React from 'react'
import {
  ModalImageSection,
  ModalNameSection,
  ModalNumberSection,
  ModalDescriptionSection,
  InputBox,
  InputLabel,
  ButtonGroup,
  NameContactEnter,
  ReadMoreButton,
  ModalImage,
  EditButton,
  SyncButton,
  ArchiveButton,
  NameContact,
  DivColumn,
  ErrorMessage,
} from './styles'
import Dialog from '../../../../../../../../shared/components/Dialog'
import imageContato from '../../../../../../../../assets/images/imageContato'
import {
  formatarDataParaBrasil,
  extractImage,
} from '../../../../../../../../utils/format'

const DialogEdit = ({
  isOpen,
  onChangeOpen,
  contactImage,
  dataContact,
  contactDateEnter,
  loading,
  verifyIsRoot,
  handleSyncImage,
  isOpenEdit,
  nameContact,
  setNameContact,
  error,
  handleSave,
  editContact,
  editedNames,
  handleArchiveContact,
  expandedDescription,
  isLongDescription,
  toggleDescription,
}) => {
  const MAX_DESCRIPTION_LENGTH = 150
  const renderEnterDate = () => {
    if (verifyIsRoot()) {
      return (
        <div>
          <span>{loading ? 'Carregando...' : 'Entrou em: '}</span>
        </div>
      )
    } else {
      return (
        <div>
          <span>{loading ? 'Carregando...' : 'Atribuído em: '}</span>
        </div>
      )
    }
  }

  const summaryContact = dataContact.summaryContact

  return (
    <Dialog open={isOpen} onChangeOpen={onChangeOpen}>
      <ModalImageSection>
        {extractImage(contactImage[dataContact.id]) !== 'null' ? (
          <ModalImage
            src={contactImage[dataContact.id]}
            alt="Imagem do contato"
          />
        ) : (
          <ModalImage src={imageContato} alt="Imagem do contato" />
        )}
      </ModalImageSection>
      <ModalNameSection>
        <NameContactEnter>
          {renderEnterDate()}
          <span>
            {formatarDataParaBrasil(contactDateEnter.contactEnterDate)}
          </span>
        </NameContactEnter>
      </ModalNameSection>
      <ModalNameSection>
        {isOpenEdit ? (
          <>
            <ButtonGroup>
              <SyncButton onClick={handleSyncImage}>
                Sincronizar Imagem
              </SyncButton>
              {dataContact.isArchivedContact ? (
                <ArchiveButton
                  onClick={() => {
                    handleArchiveContact(dataContact.id, dataContact.name)
                  }}
                >
                  Desarquivar contato
                </ArchiveButton>
              ) : (
                <ArchiveButton
                  onClick={() => {
                    handleArchiveContact(
                      dataContact.id,
                      dataContact.name,
                      !dataContact.isArchivedContact,
                    )
                  }}
                >
                  Arquivar contato
                </ArchiveButton>
              )}
            </ButtonGroup>
            <InputLabel>Nome</InputLabel>
            <InputBox
              maxLength={20}
              value={nameContact}
              onChange={(e) => setNameContact(e.target.value)}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        ) : (
          <DivColumn>
            <NameContact>
              {editedNames[dataContact.id] ||
                dataContact.name ||
                'Nome Indisponível'}
            </NameContact>
          </DivColumn>
        )}
      </ModalNameSection>
      <ModalDescriptionSection>
        <InputLabel>Descrição</InputLabel>
        <p>
          {summaryContact
            ? isLongDescription
              ? expandedDescription
                ? summaryContact
                : `${summaryContact.substring(0, MAX_DESCRIPTION_LENGTH)}...`
              : summaryContact
            : 'Sem descrição.'}
        </p>
        {isLongDescription && summaryContact && (
          <ReadMoreButton onClick={toggleDescription}>
            {expandedDescription ? 'Ler menos' : 'Ler mais'}
          </ReadMoreButton>
        )}
      </ModalDescriptionSection>
      <ModalNumberSection>{dataContact.number}</ModalNumberSection>
      <EditButton onClick={isOpenEdit ? handleSave : editContact}>
        {isOpenEdit ? 'Salvar' : 'Editar'}
      </EditButton>
    </Dialog>
  )
}

export default DialogEdit

import {
  Container,
  ContactContainer,
  ErrorMessage,
  LoadingContainer,
} from './styles'
import ContactLateral from './components/ContactLateral'
import SearchMenu from './components/SearchMenu'
import imageContato from '../../../../assets/images/imageContato'
import { format, formatMessage, getMessages } from '../../../../utils/format'
import React, { useCallback, useEffect, useState } from 'react'
import { useImage } from '../../../../hook/image'
import LoadingSpinner from '../../../../shared/components/LoadingSpinner'
import FileService from '../../../../shared/services/File/FileService'
import ContactService from '../../../../shared/services/Contact/ContactService'
import { Separator } from './components/ContactLateral/styles'
import { useTriggers } from '../../../../hook/triggers'

const MenuLateral = ({
  socket,
  setMessages,
  selectedContact,
  setSelectedContact,
  lateralContacts,
  setLateralContacts,
  navigation,
  setNavigation,
  LIMIT_MESSAGES,
  setContactFlag,
  setListArchivedContacts,
  listArchivedContacts,
  handleArchiveContact,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [selectedTags, setSelectedTags] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [archivedContacts, setArchivedContacts] = useState(false)
  const { setContactImage } = useImage()
  const { setSearchedMsg, typeSearch, setTypeSearch } = useTriggers()
  const [dateMin, setDateMin] = useState({})
  const [dateMax, setDateMax] = useState({})
  const [triggerDate, setTriggerDate] = useState(false)

  const MAX_LAST_MESSAGE_LENGTH = 25
  const LIMIT_CONTACTS = 20

  useEffect(() => {
    ContactService.getAllArchivedContacts()
      .then((resp) => {
        const list = []
        resp.data.forEach((obj) => {
          if (obj.has_unread_messages) {
            list.push(obj.id_contact)
          }
        })
        setListArchivedContacts(list)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [archivedContacts, setListArchivedContacts])

  const getBase64Media = async (fileName, fileType) => {
    try {
      const response = await FileService.getSrc(fileName, fileType)
      return response.data.base64
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
    }, 500)

    return () => {
      clearTimeout(handler)
    }
  }, [searchTerm])

  const handleLateralContacts = useCallback(() => {
    socket.emit(
      'getLateralContacts',
      {
        limit: LIMIT_CONTACTS,
        offset: 0,
        tags: selectedTags.map((tag) => tag.id_tag),
        name: searchTerm,
        archived: archivedContacts,
        serach_by: typeSearch,
        create_at_start: new Date(dateMin),
        create_at_end: new Date(dateMax),
      },
      async (err, response) => {
        if (!err) {
          const formattedData = await format(response, getBase64Media)
          formattedData.forEach((element) => {
            setContactImage(element.id, element.image)
          })
          setLateralContacts(formattedData)

          setMessages((prev) => {
            const updatedMessages = { ...prev }
            formattedData.forEach((element) => {
              if (!updatedMessages[element.id]) {
                updatedMessages[element.id] = []
              }
            })
            return updatedMessages
          })
          setIsLoading(false)
        }
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    archivedContacts,
    dateMax,
    dateMin,
    searchTerm,
    selectedTags,
    typeSearch,
    setLateralContacts,
    setMessages,
    socket,
  ])

  useEffect(() => {
    handleLateralContacts()
  }, [handleLateralContacts, debouncedSearchTerm])

  const handleContactClick = (contactId) => {
    setSelectedContact(contactId)
    setListArchivedContacts((prev) => prev.filter((id) => id !== contactId))
    socket.emit(
      'getMessages',
      { idContact: contactId },
      { limit: LIMIT_MESSAGES, offset: 0 },
      (err, response) => {
        if (!err) {
          const msg = getMessages(response)
          // console.log(msg)
          let lastMessage = msg.length > 0 ? msg[msg.length - 1].message : ''
          const typeMessage =
            msg.length > 0 ? msg[msg.length - 1].typeMessage : ''
          if (typeMessage === 'sticker') {
            lastMessage = ''
          }

          if (typeSearch === 'contacts') {
            setLateralContacts((prev) =>
              prev.map((contact) =>
                contact.id === contactId
                  ? {
                      ...contact,
                      isViewedMessage: true,
                      lastMessage,
                      unreadMessageCount: 0,
                      typeMessage,
                    }
                  : contact,
              ),
            )
            setMessages((prev) => ({
              ...prev,
              [contactId]: msg,
            }))
          }
        }
      },
    )
    setContactFlag(false)
  }

  const handleScrollContacts = () => {
    const contactContainer = document.getElementById('contactContainer')

    if (
      contactContainer.scrollHeight -
        contactContainer.scrollTop -
        contactContainer.clientHeight <=
      1
    ) {
      socket.emit(
        'getLateralContacts',
        {
          limit: LIMIT_CONTACTS,
          offset: lateralContacts.length,
          tags: selectedTags.map((tag) => tag.id_tag),
          name: searchTerm,
          archived: archivedContacts,
          serach_by: typeSearch,
          created_at_start: dateMin,
          created_at_end: dateMax,
        },
        async (err, response) => {
          if (!err) {
            const formattedData = await format(response, getBase64Media)
            formattedData.forEach((element) => {
              setContactImage(element.id, element.image)
            })
            setLateralContacts((prev) => {
              const newContacts = formattedData.filter(
                (newContact) =>
                  !prev.some(
                    (existingContact) => existingContact.id === newContact.id,
                  ),
              )
              return [...prev, ...newContacts]
            })
          }
        },
      )
    }
  }

  return (
    <>
      <Container $navigation={navigation}>
        <SearchMenu
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          setSelectedContact={setSelectedContact}
          setArchivedContacts={setArchivedContacts}
          listArchivedContacts={listArchivedContacts}
          archivedContacts={archivedContacts}
          typeSearch={typeSearch}
          setTypeSearch={setTypeSearch}
          dateMin={dateMin}
          setDateMin={setDateMin}
          dateMax={dateMax}
          setDateMax={setDateMax}
          triggerDate={triggerDate}
          setTriggerDate={setTriggerDate}
        />
        <ContactContainer id="contactContainer" onScroll={handleScrollContacts}>
          {isLoading ? (
            <LoadingContainer>
              <LoadingSpinner strokeColor="white" />
            </LoadingContainer>
          ) : lateralContacts.length === 0 ? (
            <ErrorMessage>
              {typeSearch === 'contacts'
                ? 'Nenhum contato encontrado'
                : 'Nenhuma mensagem encontrada'}
            </ErrorMessage>
          ) : (
            lateralContacts.map((contact, index) => {
              if (contact.image === `data:image/png;base64, null`) {
                contact.image = imageContato
              }
              const lastMessage = contact.lastMessage || ''
              const limitedLastMessage =
                lastMessage.length > MAX_LAST_MESSAGE_LENGTH
                  ? formatMessage(lastMessage).substring(
                      0,
                      MAX_LAST_MESSAGE_LENGTH,
                    ) + '...'
                  : formatMessage(lastMessage)
              const typeMessage = contact.typeMessage || ''

              return (
                <React.Fragment key={index}>
                  <ContactLateral
                    socket={socket}
                    contact={contact}
                    setLateralContacts={setLateralContacts}
                    typeMessage={typeMessage}
                    lastMessage={limitedLastMessage}
                    onContactClick={() => {
                      handleContactClick(contact.id)
                      if (typeSearch === 'messages') {
                        setSearchedMsg(contact)
                      }
                      setNavigation('chat')
                    }}
                    selectedContact={selectedContact}
                    handleArchiveContact={handleArchiveContact}
                    typeSearch={typeSearch}
                  />
                  <Separator />
                </React.Fragment>
              )
            })
          )}
        </ContactContainer>
      </Container>
    </>
  )
}

export default MenuLateral

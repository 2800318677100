import React from 'react'
import Dialog from '../../../../../../../shared/components/Dialog'
import InputChat from '../../../../../../../shared/components/InputChat'
import { Button, ButtonContainer, Title } from './style'
import MessageService from '../../../../../../../shared/services/Message/MessageService'
import { useTheme } from 'styled-components'
import { getMessages } from '../../../../../../../utils/format'

const EditMessage = ({
  open,
  onChangeOpen,
  message,
  setMessages,
  contactId,
  setLateralContacts,
}) => {
  const [messageState, setMessageState] = React.useState('')
  const theme = useTheme()

  // React.useEffect(() => {
  //   console.log('Mensagem recebida no modal:', message)
  // }, [message])

  const handleEditMessage = async () => {
    try {
      const res = await MessageService.update(message.idMessage, {
        contentMessage: messageState,
      })
      if (res.status === 200) {
        setMessages((prev) => {
          const newMessages = { ...prev }
          if (Array.isArray(newMessages[contactId])) {
            newMessages[contactId] = newMessages[contactId].map((msg) => {
              if (msg.idMessage === message.idMessage) {
                return getMessages([res.data])[0]
              }
              return msg
            })
          }
          return newMessages
        })
        setLateralContacts((prev) => {
          const newContacts = [...prev]
          newContacts.map((contact) => {
            if (contact.id === contactId) {
              if (contact.lastMessageId === message.idMessage) {
                contact.lastMessage = messageState
              }
            }
            return contact
          })

          return newContacts
        })
      }

      onChangeOpen(false)
    } catch (error) {
      console.error('Erro ao editar a mensagem:', error)
    }
  }

  return (
    <Dialog open={open} onChangeOpen={onChangeOpen}>
      <Title>Edite a mensagem:</Title>
      <InputChat
        value={messageState}
        onChange={(e) => setMessageState(e.target.value)}
        placeholder={message.message?.replace(/^\n+/, '')}
        handleSendMessage={handleEditMessage}
        style={{
          backgroundColor: theme.colors.guto.FundoAzul,
        }}
      />
      <ButtonContainer>
        <Button onClick={() => onChangeOpen(false)}>Cancelar</Button>
        <Button
          onClick={handleEditMessage}
          disabled={messageState === message.message}
        >
          Editar
        </Button>
      </ButtonContainer>
    </Dialog>
  )
}

export default EditMessage
